import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldRHF, DropdownRHF, Loading } from '@components';
import {
  useGetMerchantStoresQuery,
  useI18n,
  useMerchantSettingGroup,
} from '@hooks';
import {
  IEditUserGroup,
  IUserGroupIds,
} from '@schemas/merchantPortal/types/editUserGroups';
import { IGroup } from '@schemas/merchantPortal/types/groups';
import {
  getGroupsDropdownOptions,
  getMerchantStoresDropdownOptions,
} from './getDropdownOptions';
import { getValidationSchema } from './getValidationSchema';
import { TooltipContent } from './TooltipContent';

interface IUserForm {
  formID: string;
  groups: IGroup[];
  handleSubmit: (values: IEditUserGroup) => void;
  values: IEditUserGroup;
}

export const UserForm = ({
  formID,
  groups,
  handleSubmit,
  values,
}: IUserForm) => {
  const { translate } = useI18n();
  const { showMerchantStoresPage } = useMerchantSettingGroup();
  const { isMerchantStoresLoading, merchantStoresData } =
    useGetMerchantStoresQuery();

  // I realise this looks hideous, but it is a necessary evil, as
  // default values in destructuring only replace undefined, not null.
  const defaultValues = {
    email: values?.email ?? '',
    external_uuid: values?.external_uuid,
    first_name: values?.first_name ?? '',
    group: values?.group ?? ('' as unknown as IUserGroupIds),
    last_login: values?.last_login,
    last_name: values?.last_name ?? '',
    phone_number: values?.phone_number ?? '',
    store: values?.store ?? '',
    merchant_store_uuid: values?.merchant_store_uuid,
  };

  const methods = useForm<IEditUserGroup>({
    defaultValues,
    resolver: yupResolver(getValidationSchema(translate)),
  });

  const handleFormSubmit = (formData: IEditUserGroup) => {
    const preparedData = {
      ...formData,
      merchant_store_uuid: formData.merchant_store_uuid || null,
    };
    handleSubmit(preparedData);
  };

  if (showMerchantStoresPage && isMerchantStoresLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...methods}>
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
        onSubmit={methods.handleSubmit(handleFormSubmit)}
      >
        <TextFieldRHF
          id="email"
          label={translate('LABEL.EMAIL_ADDRESS')}
          name="email"
        />
        <TextFieldRHF
          id="first_name"
          label={translate('LABEL.FIRST_NAME')}
          name="first_name"
        />
        <TextFieldRHF
          id="last_name"
          label={translate('LABEL.LAST_NAME')}
          name="last_name"
        />
        <TextFieldRHF
          id="phone_number"
          label={translate('LABEL.PHONE_NUMBER')}
          name="phone_number"
        />
        {showMerchantStoresPage ? (
          <DropdownRHF
            id="merchant_store_uuid"
            label={translate('TITLE.STORE_NAME')}
            name="merchant_store_uuid"
            options={getMerchantStoresDropdownOptions(
              merchantStoresData?.results,
            )}
          />
        ) : (
          <TextFieldRHF
            id="store"
            label={translate('TITLE.STORE_ID')}
            name="store"
          />
        )}
        <DropdownRHF
          id="group"
          label={translate('PAGE.USERS.PERMISSIONS_GROUP')}
          name="group"
          options={getGroupsDropdownOptions(groups, translate)}
          tooltip={{
            content: <TooltipContent />,
            label: translate('PAGE.USERS.PERMISSIONS_GROUP'),
          }}
        />
      </form>
    </FormProvider>
  );
};
