import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import {
  DataDisplay,
  EditorField,
  TextFieldRHF as TextField,
} from '@components';
import { CommunicationIframe } from '@components/Common.styles';
import { useI18n } from '@hooks';
import { IEmailUserFormProps } from './types';
import { useCustomEmailForm } from './useCustomEmailForm';

const Viewer = styled.div`
  margin: -8px;
`;

export const EmailUserForm = ({
  currency,
  formID,
  handleSendEmail,
  isInArrears,
  isInternalOpsUser,
  selectedTemplate,
}: IEmailUserFormProps) => {
  const { translate } = useI18n();

  const defaultValues = {
    body_html: selectedTemplate.html,
    subject: selectedTemplate.subject,
  };

  const { methods, TemplateFields } = useCustomEmailForm({
    currency,
    defaultValues,
    isInArrears,
    triggerId: selectedTemplate.trigger_id,
  });
  const { handleSubmit } = methods;

  const subject = translate('OPS_PORTAL.LABEL.SUBJECT');
  const body = translate('OPS_PORTAL.LABEL.BODY');
  const fontFamily = "font-family: 'HeyLight Sans', sans-serif;";
  const iframeStyle = `font-size: 13px; ${fontFamily} font-weight: 400; color: #3F4254`;
  const iframeContent = `<div style="${iframeStyle}">${selectedTemplate.html}</div>`;

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={handleSubmit(handleSendEmail)}>
        {TemplateFields && isInternalOpsUser ? (
          <TemplateFields methods={methods} />
        ) : null}
        {isInternalOpsUser ? (
          <>
            <TextField label={subject} name="subject" />
            <EditorField name="body_html" />
          </>
        ) : (
          <DataDisplay
            dataList={[
              { key: subject, value: selectedTemplate.subject },
              {
                key: body,
                value: (
                  <Viewer>
                    <CommunicationIframe src={iframeContent} />
                  </Viewer>
                ),
              },
            ]}
          />
        )}
      </form>
    </FormProvider>
  );
};
